import React, { Component } from "react"
import _ from "lodash"
import queryString from "query-string"

class FsCallback extends Component {
  componentDidMount() {
    if (typeof window !== "undefined") {
      const referrer = queryString.parseUrl(document.referrer)
      const currentPage = window.location.origin + window.location.pathname
      if (referrer.url && referrer.url !== currentPage) {
        let backslash = ""
        if (!_.endsWith(referrer.url, "/")) {
          backslash = "/"
        }

        window.location.href = `${referrer.url}${backslash}?registration=complete`
      }
    }
  }

  render() {
    return <div />
  }
}

export default FsCallback
